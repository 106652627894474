import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { campaignClient } from "../api";
import { byKey } from "./utils";
import axios from "axios";
import dayjs from "dayjs";
import { KeywordData } from "../types/keywordTypes";
import { storeKeyword } from "../stores/KeywordStore";




const useAuth = (authStore: any) => {
  const [isAuth, setIsAuth] = useState({
    auth: false,
    loading: true,
  });

  useEffect(() => {
    const handleVerifyLogin = () => {
      try {
        //await authStore.verifyLogin();

        const user = authStore.getUser();

        if (user.token) {
          setIsAuth({
            auth: true,
            loading: false,
          });
        } else {
          setIsAuth({
            auth: false,
            loading: false,
          });
        }
      } catch (error) {
        console.log("use auth", error);
      }
    };

    handleVerifyLogin();
  }, [authStore]);

  return { isAuth };
};


export const useBodyClass = (klassName: string) => {
  useEffect(() => {
    document.body.className = klassName;
  }, [klassName]);

  return () => {
    document.body.className = "";
  };
};


export function useGetGrandprizedraws(keywordKey: string) {
  return useQuery({
    queryKey: ["getDrawMonths", keywordKey],
    queryFn: async () => {
      if (!keywordKey) {
        return []
      }
      const res: MainDbReturnTypes.GrandPrizeDraws = await campaignClient.call(
        "getGrandPrizeDraws",
        {}
      );
      const tag = `keyword:${keywordKey}`;
      return res.grandPrizeDraws.filter((x: any) => x.parameters.allTags?.includes(tag));
    }
  })
}

export function useGetDrawsForKeywordAndLocation(keywordKey: string,
  keywordLocationKey: string) {
  return useQuery({
    queryKey: ["getDrawMonths"],
    queryFn: async () => {
      const res: MainDbReturnTypes.GrandPrizeDraws = await campaignClient.call(
        "getGrandPrizeDraws",
        {}
      );
      const tag1 = `keyword:${keywordKey}`;
      const tag2 = `location:${keywordLocationKey}`;
      return res.grandPrizeDraws.filter(
        (x: any) => x.parameters.allTags?.find(tag1) && x.allTags?.find(tag2)
      );
    }
  })
}

export function useGetPrizes() {
  return useQuery({
    queryKey: ["getPrizes"],
    queryFn: async () => {
      const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});
      const prizes = byKey(res.prizes, (x) => x.prizeKey);
      return prizes;
    }
  });
}


export function useGetParticipants(keywordKey: string) {
  return useQuery(["getParticipants", keywordKey], async () => {
    if (!keywordKey) {
      return
    }
    const res: any = await campaignClient.call("getParticipants", {
      allTags: [`keyword:${keywordKey}`],
    });

    let participants;

    if (res.participants) {
      participants = res.participants;
    }
    if (res.result) {
      participants = res.result.participants;
    }
    if (res.largeResultUrl) {
      const result = await axios(res.largeResultUrl);

      const response: MainDbReturnTypes.Participant[] = await result.data.result.participants;
      participants = response;
    }
    return participants.filter((p: any) => p.firstName);;
  }, { enabled: !!keywordKey });


}
export function useGetPrizeWinners(keywordStartTime: string, grandPrizeDraws: MainDbReturnTypes.GrandPrizeDraw[] | undefined, guestCount: number | undefined) {
  return useQuery({
    queryKey: ["getWinners", keywordStartTime],
    queryFn: async () => {

      let winners: MainDbReturnTypes.PrizeWinner[] = [];

      if (!grandPrizeDraws) {
        return;
      }

      for (let draw of grandPrizeDraws) {
        const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {
          grandPrizeDrawKey: draw.grandPrizeDrawKey,
        });

        // filter winners created before the keyword start date

        const filtered = res.prizeWinners.filter(w => !dayjs(w.creationTime).isBefore(dayjs(keywordStartTime)))

        winners.push(...filtered);

        if (guestCount) {
          for (let p of res.prizeWinners) {
            const guests: MainDbReturnTypes.Participants = await campaignClient.call(
              "getParticipants",
              { allTags: [`guest-of:${p.participant.sessionKey}`] }
            );
            if (guests.participants.length) {
              for (let w of guests.participants) {
                const guests: MainDbReturnTypes.PrizeWinners = await campaignClient.call(
                  "getPrizeWinners",
                  { sessionKey: w.sessionKey }
                );
                if (guests.prizeWinners.length) {
                  const filter_current = guests.prizeWinners.filter(g => g.prizePoolKey === draw.prizePoolKey)
                  for (let p of filter_current) {
                    winners.push(p);

                  }

                }
              }
            }
          }
        }


      }

      return winners;
    },
    enabled: !!keywordStartTime && !!grandPrizeDraws
  })

}

export function useGetGuestCount(keyword: storeKeyword) {
  let guestCountPerWinner = keyword.dynamicSettings.guestCount
  let config = keyword.dynamicSettings.prizeConfig
  if (config) {

    for (let prize in config) {
      if (config[prize].config.guestCount) {
        guestCountPerWinner = config[prize].config.guestCount
      }
    }

  }
  return guestCountPerWinner
}

export { useAuth };

