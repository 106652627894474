import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useStores } from "../../stores";
import CustomLink from "../CustomLink";

function SideBar() {
  const [profile, setProfile] = useState("");

  const { keywordStore } = useStores();
  const [keyword, setKeyword] = useState("");
  const filter = keywordStore.keyword;

  const getProfile = async () => {
    let user = await Auth.currentAuthenticatedUser();
    setProfile(user.attributes.profile);
  };

  useEffect(() => {
    getProfile();
  }, []);
  useEffect(() => {
    setKeyword(filter.word);
  }, [filter]);

  return (
    <>
      <nav className="nav sidebar__nav">
        <ul style={{ paddingLeft: "0" }}>
          {profile.toLowerCase() === "standard" ||
            profile.toLowerCase() === "admin" ||
            profile.toLowerCase() === "cmservice" ? (
            <>
              <CustomLink to="overview">
                <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
                </svg>
                <span> Overview</span>
              </CustomLink>
              <CustomLink to="keywordoverview">
                <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
                </svg>
                <span>Keyword Overview</span>
              </CustomLink>
              <CustomLink to={"participants"}>
                <svg className="ico-user">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-user" />
                </svg>
                <span>Participants</span>
              </CustomLink>
              <CustomLink to="winners">
                <svg className="ico-star">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-star" />
                </svg>
                <span>Winners</span>
              </CustomLink>

              <CustomLink to="dashboard">
                <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
                </svg>
                <span> Dashboard</span>
              </CustomLink>

            </>
          ) : (
            <></>
          )}
          {(profile.toLowerCase() === "shipping") &&
            <>
              <CustomLink to="dashboard">
                <svg className="ico-bolt" viewBox="0 0 14 16" width="50">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-bolt" />
                </svg>
                <span> Dashboard</span>
              </CustomLink>

              <CustomLink to="winners">
                <svg className="ico-star">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-star" />
                </svg>
                <span>Winners</span>
              </CustomLink>
            </>
          }

          {profile.toLowerCase() === "admin" ? (
            <>
              <CustomLink to="manage">
                <svg className="ico-account2">
                  <use xlinkHref="/assets/images/svg/sprite.svg#ico-account2" />
                </svg>
                <span>User Management</span>
              </CustomLink>

            </>
          ) : (
            <></>
          )}
        </ul>
        <div className="subMenuCont">
          {keyword && (
            <>
              <p>
                {" "}
                <span>{keyword[0].toUpperCase() + keyword.slice(1)}</span>
                <button
                  onClick={async () => {
                    setKeyword("");
                    keywordStore.setKeyword({
                      word: "",
                      keywordKey: "",
                      shipping: "",
                      contestType: "",
                      dynamicSettings: {},
                      startTime: "", endTime: ""
                    });
                    window.location.reload();
                  }}
                  className="nameLink">
                  <img src="/assets/images/ico-refresh.png" alt="edit" className="icoRefresh" />{" "}
                </button>
              </p>
            </>
          )}
        </div>
      </nav>
    </>
  );
}

export default SideBar;
