import dayjs from "dayjs";
import _ from "lodash";
import { campaignClient } from "../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";

export const imageUrlToBase64 = async (url: string) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = reject;
  });
};

export function byKey<T>(records: T[], getKey: (t: T) => string) {
  return records.reduce((prev, curr) => {
    const key = getKey(curr);
    if (!prev[key]) {
      prev[key] = [];
    }
    prev[key].push(curr);
    return prev;
  }, {} as { [key: string]: T[] });
}

export const getDaysBetween2Dates = (startDate: string, endDate: string) => {
  let currentDate = dayjs(startDate);
  const date_array: string[] = [currentDate.toISOString()];

  while (currentDate.isBefore(dayjs(endDate))) {
    // console.log(currentDate);
    currentDate = dayjs(currentDate).add(1, "day");
    date_array.push(currentDate.toString());
  }
  return date_array;
};

export const getPastMonths = (past: number) => {
  let monthArray = [];
  let currentMonth = dayjs();
  do {
    monthArray.push(currentMonth.format("MMM YY"));
    let previousMonth = dayjs(currentMonth).subtract(30, "day");

    currentMonth = dayjs().set("month", previousMonth.month()).set("year", previousMonth.year());
  } while (monthArray.length < past);
  return monthArray;
};

export const emailRegex =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
export const looseNorthAmericanPhoneRegex =
  /^\+?1?[.() -]{0,2}[2-9][0-8][0-9][.() -]{0,2}[2-9][0-9]{2}[.() -]?[0-9]{4}$/;
export const canadianPostalCodeRegex = /^[a-z][0-9][a-z][ -]?[0-9][a-z][0-9]$/i;
export const americanZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const stringRegex =
  /^[a-zA-ZÀÁÂÃÄÅàáâãäåÒÓÔÕÖØòóôõöøÈÉÊËèéêëÇçÌÍÎÏìíîïÙÚÛÜùúûüÿÑñ \-']{2,64}$/i;

export function moveHashHeaderLast(columns: any[]) {
  // Find the index of the column with the "#" header
  const hashIndex = columns.findIndex((column) => column.Header === "#");
  // If the "#" header is not found, return the original array
  if (hashIndex === -1) {
    return columns;
  }
  // Remove the column with the "#" header and save it in a variable
  const hashColumn = columns.splice(hashIndex, 1)[0];
  // Push the column with the "#" header to the end of the array
  columns.push(hashColumn);
  // Return the modified array
  return columns;
}

export const provinces: { [key: string]: string } = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland and Labrador",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  QC: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const validKeywords = [
  "address",
  "birthdate",
  "gender",
  "locale",
  "nickname",
  "phone_number",
  "picture",
  "preferred_username",
  "website",
  "zoneinfo",
];






export const removeWhiteSpacesForPassword = () => {
  // The below is the logic to remove white spaces from the password field.
  document.addEventListener("click", function (event) {
    const input = document.querySelector("input[name='password']") as HTMLInputElement;
    if (event.target === input) {
      event.preventDefault();
    }
    input &&
      input.addEventListener("input", function () {
        const trimmedValue = _.trim(input.value);
        input.value = trimmedValue.replaceAll(" ", "");
      });
  });

  document.addEventListener("click", function (event) {
    const input = document.querySelector("input[name='confirm_password']") as HTMLInputElement;
    if (event.target === input) {
      event.preventDefault();
    }
    input &&
      input.addEventListener("input", function () {
        const trimmedValue = _.trim(input.value);
        input.value = trimmedValue.replaceAll(" ", "");
      });
  });
};

export function getMatchLocation({
  participantTags,
  prizeDrawTags,
  keywordLocations,
}: {
  participantTags: string[];
  prizeDrawTags: string[];
  keywordLocations: { keywordLocationKey: string; name: string }[];
}) {
  let matchLocation: { keywordLocationKey: string; name: string } | undefined;
  const keywordLocationKey = prizeDrawTags.find((x) => x.startsWith("location:"))?.split(":")[1];
  const locationKey = participantTags.filter((x) => {
    return keywordLocationKey
      ? x.startsWith(`location:${keywordLocationKey}`)
      : x.startsWith("location:");
  });
  const keywordLocation = byKey(keywordLocations, (lc) => lc.keywordLocationKey);
  for (let location of locationKey) {
    const key = location.split(":")[1];
    if (keywordLocation[key]) {
      matchLocation = keywordLocation[key][0];
      break;
    }
  }
  return matchLocation;
}

export function getPrizeDrawLocation({
  participantTags,
  prizeDrawTags,
  keywordLocations,
}: {
  participantTags: string[];
  prizeDrawTags: string[];
  keywordLocations: { keywordLocationKey: string; name: string }[];
}) {
  let matchLocation: { keywordLocationKey: string; name: string } | undefined;
  const prizeDrawLocationKeys = prizeDrawTags
    .filter((x) => x.startsWith("location:"))
    .map((t) => t?.split(":")[1]);

  const matchedPartcipantLocation = participantTags.filter((x) => {
    return prizeDrawLocationKeys ? prizeDrawLocationKeys.includes(x.split(":")[1]) : x;
  });
  const keywordLocation = byKey(keywordLocations, (lc) => lc.keywordLocationKey);
  for (let location of matchedPartcipantLocation) {
    const key = location.split(":")[1];
    if (keywordLocation[key]) {
      matchLocation = keywordLocation[key][0];
      break;
    }
  }
  return matchLocation?.name || "";
}
