import { Row, TableProps } from "react-table";

export const TableColumns = ({setKeywordSelected}:{setKeywordSelected:Function}) => {

    const COLUMNS = [
        {
            Header: "No.",
            accessor: "colNumber",
            width: "3.6%",
            Cell: (TableInfo: TableProps) => {
                const nmb = TableInfo.row.index + 1;
                return nmb;
            },
        },
        {
            Header: "Keyword",
            accessor: "keyword",
            width: "7.7%",
        },
        {
            Header: "Launch Date",
            accessor: "startDate",
            width: "7.7%",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].startDate
                    ? TableInfo.data[TableInfo.row.index].startDate.toLocaleString()
                    : "N/A";
            },
        },
        {
            Header: "Close Date",
            accessor: "endDate",
            width: "13.1%",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].endDate
                    ? TableInfo.data[TableInfo.row.index].endDate.toLocaleString()
                    : "N/A";
            },

        },
        {
            Header: "Notification Date",
            accessor: "notificationDate",
            width: "5.1%",
            sortType: (rowA: Row, rowB: Row, columnId: number) => {
                if (rowA.values[columnId] > rowB.values[columnId]) return 1;
                if (rowB.values[columnId] > rowA.values[columnId]) return -1;
                return 0;
            },
            Cell: (TableInfo: TableProps) => {
                return TableInfo.data[TableInfo.row.index].notificationDate
                    ? TableInfo.data[TableInfo.row.index].notificationDate.toLocaleString()
                    : "N/A";
            },

        },
        {
            Header: "# Possible Winners",
            accessor: "possibleWinners",
            width: "5.1%",
           
        },
        {
            Header: "# Claimed Prizes",
            accessor: "claimedPrizes",
            width: "5.1%",

        },
        {
            Header: "File",
            accessor: "file",
            Cell: (TableInfo: TableProps) => {
                const keyword= TableInfo.data[TableInfo.row.index].keywordKey
                return <button onClick={()=>{setKeywordSelected(keyword)}} style={{border:"none"}}>
                              <img className="csv" src="/assets/images/temp/csv-file.png" alt="download here" height={35} width={35}/>

                </button>
            },
            
        },

    ];
    return COLUMNS;
};
