import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import SearchKeyword from "../../Components/SearchKeyword";
import FilteredParticpants from "./FilteredParticipants";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import { byKey } from "../../helpers/utils";
import axios from "axios";
import dayjs from "dayjs";
import { useGetGrandprizedraws, useGetGuestCount, useGetParticipants, useGetPrizes, useGetPrizeWinners } from "../../helpers/hooks";

function Participants() {
  const storeKeyword = useStores().keywordStore;
  const [keyword, setKeyword] = useState(storeKeyword.keyword);
  const navigate = useNavigate();
  const profile = useStores().authStore.user.profile;
  const grandPrizeDraws = useGetGrandprizedraws(keyword.keywordKey)
  const guestCount = useGetGuestCount(keyword)
  const prizeWinners = useGetPrizeWinners(keyword.startTime, grandPrizeDraws.data,guestCount)
  const participants = useGetParticipants(keyword.keywordKey)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (storeKeyword.keyword.word) {
      setKeyword(storeKeyword.keyword);
    } else {
      navigate("/participants");
    }
  }, [storeKeyword.keyword, navigate]);

  const { isLoading: isLoadingPools, data: prizePool } = useQuery(
    ["getPrizePools", { keyword }],
    async () => {
      const res: MainDbReturnTypes.PrizePools = await campaignClient.call("getPrizePools", {});
      return res.prizePools.find((p) => p.prizePoolName === keyword.word);
    },
    { enabled: keyword.keywordKey ? true : false }
  );


  const { isLoading: isLoadingEntries, data: grandPrizeEntries } = useQuery(
    ["getGrandPrizeEntries2", { prizePool }],
    async () => {
      const res: any = await campaignClient.call(
        "getGrandPrizeEntries",
        {
          prizePoolKey: prizePool?.prizePoolKey,
        }
      );

      let entries;

      if (res.grandPrizeEntries) {
        entries = res.grandPrizeEntries;
      }
      if (res.result) {
        entries = res.result.grandPrizeEntries;
      }
      if (res.largeResultUrl) {
        const result = await axios(res.largeResultUrl);

        const response: MainDbReturnTypes.GrandPrizeEntry[] = await result.data.result.grandPrizeEntries;
        entries = response;
      }
      const filtered = entries.filter((w: any) => !dayjs(w.creationTime).isBefore(dayjs(keyword.startTime)))

      return filtered;
    },
    { enabled: prizePool ? true : false }
  );

  const { isLoading: isLoadingLocations, data: keywordLocations } = useQuery(
    ["getKeywordLocations", { keyword }],
    async () => {
      const res: MainDbReturnTypes.KeywordLocations = await campaignClient.call(
        "getKeywordLocations",
        {
          keywordKey: keyword.keywordKey,
        }
      );
      return res.keywordLocations;
    },
    { enabled: keyword.keywordKey ? true : false }
  );


  const prizes = useGetPrizes()

  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getLegalName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null
  ) => {
    if (firstName && lastName) {
      return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
    } else if (firstName) {
      return capitalizeFirstLetter(firstName);
    } else return "N/A";
  };

  const winnersData = useMemo(() => {
    if (
      participants.data &&
      keywordLocations &&
      prizes.data &&
      grandPrizeEntries
    ) {
      return participants.data.map((rp: MainDbReturnTypes.Participant) => {
        const winner = prizeWinners.data?.find((w) => w.participant.sessionKey === rp.sessionKey);
        const entry = grandPrizeEntries.find((e: MainDbReturnTypes.GrandPrizeEntry) => e.participant.sessionKey === rp.sessionKey);
        const newParticipant = {
          creationTime: entry?.creationTime ? new Date(entry?.creationTime) : "",
          firstName: rp.firstName || "N/A",
          lastName: profile.toLowerCase() === "standard" ? "" : rp.lastName,
          email: rp.email,
          phone: rp.phone,
          city: rp.city,
          province: rp.province,
          prizeWinTime: winner && new Date(winner.creationTime).toLocaleString(),
          fulfillTime: winner?.declarationAndRelease
            ? new Date(winner.declarationAndRelease.creationTime)
            : "NA",
          updateTime: rp.updateTime ? new Date(rp.updateTime) : "NA",
          status: winner ? (winner?.declarationAndRelease ? "Claimed" : "Pending Claim") : "",
          prize: winner
            ? prizes.data[winner.prizeKey]
              ? prizes.data[winner.prizeKey][0].prizeName
              : "NA"
            : "N/A",
          sessionKey: rp.sessionKey,
          prizeWinnerKey: winner?.prizeWinnerKey || "N/A",
          keyword: keyword.word,
          stores: keywordLocations.length
            ? rp.tags
              .filter((t) => t.includes("location"))
              .map(
                (l) =>
                  keywordLocations.find(
                    (p) => p.keywordLocationKey === l.replace("location:", "")
                  )?.name
              )
              .filter((l) => l)
              .join(",")
            : "",
          winnerType: "Primary",
          expirationDate: "",
          declaration: {
            firstName: winner?.declarationAndRelease?.firstName,
            lastName: winner?.declarationAndRelease?.lastName,
            legalName:
              winner?.declarationAndRelease &&
              getLegalName(
                winner?.declarationAndRelease.firstName,
                winner?.declarationAndRelease?.lastName
              ),
            address: winner?.declarationAndRelease?.address,
            address2: winner?.declarationAndRelease?.address2,
            city: winner?.declarationAndRelease?.city,
            province: winner?.declarationAndRelease?.province,
            postal: winner?.declarationAndRelease?.postal,
            phone: winner?.declarationAndRelease?.phone,
          },
        };
        return newParticipant;
      });
    }
  }, [prizeWinners.data, participants.data, keyword.word, keywordLocations, prizes.data, profile, grandPrizeEntries]);

  if (!keyword.keywordKey) {
    return <SearchKeyword />;
  }
  if (
    prizeWinners.isLoading ||
    participants.isLoading ||
    isLoadingLocations ||
    isLoadingEntries ||
    isLoadingPools ||
    prizes.isLoading
  ) {
    return <PageLoader />;
  }

  return (
    <>
      <FilteredParticpants winnersData={winnersData ? winnersData : []} />
    </>
  );
}

export default Participants;
