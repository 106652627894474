import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import CustomRoutes from "./Config/Routes";
import { useStores, StoreContext } from "./stores";
import { Login, Participant, Participants, Winners } from "./Pages";
import BaseLayout from "./Components/Layout/BaseLayout";
import { Auth } from "aws-amplify";
import { setupApiClient } from "./api";
import Admins from "./Pages/Admins";
import { Layout } from "./Components";
import { useEffect, useState } from "react";
import { Download } from "./Pages/Download";
import Dashboard from "./Pages/Dashboard";

declare const window: Window &
  typeof globalThis & {
    vex: any;
  };

function App() {
  setInterval(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const { refreshToken } = cognitoUser.getSignInUserSession();
      cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
        setupApiClient(session.idToken.jwtToken);
      });
    } catch (e) {
      throw e;
    }
  }, 1200000);

  const stores = useStores();

  const { route, user } = useAuthenticator((context) => [context.route]);

  const [profile, setProfile] = useState("");

  const getProfile = async () => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      setProfile(user.attributes.profile);
    } catch { }
  };
  useEffect(() => {
    getProfile();

    if (route === "authenticated" && user) {
      if (
        (user.attributes && user.attributes.profile?.toLowerCase() === "admin") ||
        (user.attributes && user.attributes.profile?.toLowerCase() === "standard") ||
        (user.attributes && user.attributes.profile?.toLowerCase() === "shipping") ||
        (user.attributes && user.attributes.profile?.toLowerCase() === "cmservice")
      ) {
        stores.authStore.setup();
        stores.authStore.setUserName(
          user.username!,
          "",
          user.attributes.profile?.toLowerCase(),
          true
        );
      } else {
        window.vex.dialog.alert({
          unsafeMessage: `This is not an authorized account to access this portal.`,
          callback: async () => {
            await Auth.signOut({ global: true });
            return;
          },
        });
      }
    }
  }, [user]);
  console.log(profile)

  return (route === "authenticated" && user.attributes?.profile.toLowerCase() === "admin") ||
    (route === "authenticated" && user.attributes?.profile.toLowerCase() === "standard") ||
    (route === "authenticated" && user.attributes?.profile.toLowerCase() === "cmservice") ||
    (route === "authenticated" && user.attributes?.profile.toLowerCase() === "shipping") ? (
    <StoreContext.Provider value={stores}>
      <Routes>

        {profile.toLowerCase() === "standard" && <>{CustomRoutes}</>}
        {(profile.toLowerCase() === "admin" || profile.toLowerCase() === "cmservice") && (
          <>
            {CustomRoutes}
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/overview" />} />
              <Route path="manage" element={<Admins />} />
              <Route path="participants" element={<Participants />} />
              <Route path="participant/:key" element={<Participant />} />
              <Route index element={<Navigate to="/overview" />} />
            </Route>
          </>
        )}
        {(profile.toLowerCase() === "shipping") && (
          <>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/dashboard" />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="winners" element={<Winners />} />
              <Route path="*" element={<Navigate to="/dashboard" />} />
            </Route>
          </>
        )}
        <Route path="/d/:key" element={<BaseLayout><Download /></BaseLayout>} />
      </Routes>
    </StoreContext.Provider>
  ) : (
    <Routes>
      <Route path="/" element={<BaseLayout><Login /></BaseLayout>} />
      <Route path="/d/:key" element={<BaseLayout><Download /></BaseLayout>} />
      <Route path="*" element={<BaseLayout><Login /></BaseLayout>} />
    </Routes>
  );
}

export default App;
