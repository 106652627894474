import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query';
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { campaignClient } from '../../api';
import { TableColumns } from "./dashboardcols";
import { PageLoader, Table } from '../../Components';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import { useStores } from '../../stores';



const Dashboard = () => {
  const [filterTable, setFilterTable] = useState("");
  const [keywordSelected, setKeywordSelected] = useState("")
  const [fileReady, setFileReady] = useState(false);
  const csvRef = useRef<HTMLButtonElement | null>(null)
  const userProfile = useStores().authStore.user.profile.toLowerCase()
  const visibility = (userProfile === "admin" || userProfile == "shipping")

  const { isLoading: loadingKeywords, data: keywords } = useQuery(["getAllKeywords", {}], async () => {
    const { keywords }: { keywords: MainDbReturnTypes.Keyword[] } = await campaignClient.call(
      "getKeywords",
      {}
    );
    return keywords.sort(function (a, b) {
      return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
    }).filter(k => dayjs(k.startTime).year() >= 2024);
  })
  const { isLoading: winnersLoading, data: winners } = useQuery(["getAllPrizeWinners", {}], async () => {
    const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call(
      "getPrizeWinners",
      {}
    );
    return res.prizeWinners
  })

  const data = useMemo(() => {
    if (keywords?.length && winners) {

      return keywords.map(k => {

        const keyword_winners = winners.filter(w => w.prizePoolKey == k.basicSettings.primaryPrizePoolKey && !dayjs(w.creationTime).isBefore(dayjs(k.startTime)))

        return {
          keywordKey: k.keywordKey,
          keyword: k.word,
          startDate: new Date(k.startTime),
          endDate: new Date(k.endTime),
          notificationDate: k.dynamicSettings?.shipping?.notification?.notificationTime || "",
          possibleWinners: k.dynamicSettings.prizeCount,
          claimedPrizes: keyword_winners.filter(w => w.declarationAndRelease?.creationTime).length,
          File: "",

        }
      })
    }
  }, [keywords, winners])

  const headersForCsv = [{ label: "Cust_no", key: "Cust_no" }, { label: "Ship_toCode", key: "Ship_toCode" }, { label: "Attention_To", key: "Attention_To" }, { label: "Ship_CompanyName", key: "Ship_CompanyName" },
  { label: "Ship_address", key: "Ship_address" }, { label: "Ship_city", key: "Ship_city" }, { label: "Ship_postalCode", key: "Ship_postalCode" }, { label: "Ship_province", key: "Ship_province" },
  { label: "country", key: "country" }, { label: "line type", key: "line_type" }, { label: "SalesType", key: "SalesType" }, { label: "Cust_Order_Number", key: "Cust_Order_Number" },
  { label: "Weight", key: "Weight" }, { label: "Part_no", key: "Part_no" }, { label: "Project_code", key: "Project_code" }, { label: "budget code", key: "budgetCode" },
  { label: "Qty", key: "Qty" }, { label: "SO Type", key: "SO_Type" }, { label: "Use CSV", key: "Use_CSV" }, { label: "email-address", key: "email" },
  { label: "shippingmethod", key: "shippingmethod" }, { label: "external_notes", key: "external_notes" },
  ]
  const csvData = useMemo(() => {
    if (keywordSelected && winners && keywords) {

      const temp: any[] = []

      const currentKeyword = keywords.find(k => k.keywordKey == keywordSelected)
      const keyWordWinners = winners.filter(w => w.prizePoolKey == currentKeyword?.basicSettings.primaryPrizePoolKey)

      for (let w of keyWordWinners) {
        if (w.declarationAndRelease?.creationTime) {
          const { firstName, lastName, address, address2, city, country, email, postal, province, } = w.declarationAndRelease
          temp.push({
            "Ship_CompanyName": firstName + " " + lastName,
            "Ship_address": address + (address2 ? "," + address2 : ""),
            "Ship_city": city,
            "Ship_postalCode": postal,
            "Ship_province": province,
            "country": country,
            "Part_no": currentKeyword?.dynamicSettings?.shipping?.notification?.provider?.partNumber || "",
            "Project_code": currentKeyword?.dynamicSettings?.shipping?.notification?.provider?.projectNumber || "",
            "Qty": 1,
            "email": email,
            "external_notes": currentKeyword?.word
          })
        }
      }
      return temp
    } else {
      return []
    }
  }, [keywordSelected])
  useEffect(() => {

    if (csvData && keywordSelected) {
      csvRef.current && csvRef.current.click()

    }
  }, [keywordSelected])

  const columns = TableColumns({ setKeywordSelected });

  if (loadingKeywords || winnersLoading) {
    return <PageLoader />
  }
  return (
    <div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>

          </div>
        </div>
      </div>
      <CSVLink data={csvData} headers={headersForCsv} filename="data.csv">
        <button className="btn" ref={csvRef} style={{ display: "none" }}>Download CSV</button>
      </CSVLink>

      {keywords?.length ? <>

        <div className="tabs__body mt-5">
          <div className="table table--alt table--tabs table--big">
            <Table
              columns={columns}
              data={data || []}
              tablePageSize={15}
              filterValue={filterTable}
              sortbyid="startDate"
              desc={true}
              hideColumns={visibility ?[]: ["file"]}

            // hideColumns={hideColumns}
            />
          </div>
        </div>

      </> : <></>}

    </div>
  )
}

export default Dashboard