import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { campaignClient } from "../../api";
import { PageLoader, Table } from "../../Components";
import { showToast } from "../../Components/Toast/ToastManager";
import { useStores } from "../../stores";
import ClaimformPdf from "./claimformPdf";
import { TableColumns } from "./WinnerCol";
import jsPDF from "jspdf";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { imageUrlToBase64 } from '../../helpers/utils'
import { useGetGuestCount } from "../../helpers/hooks";

export type filteredWinners = {
  firstName: string;
  lastName: string;
  language: string;
  email: string;
  phone: string;
  // city: participant.city,
  // province: participant.province,
  prizeWinTime: Date | string;
  prizeClaimTime: Date | string;
  fulfillTime: Date | string;
  updateTime: Date | string;
  status: string;
  prize: string;
  sessionKey: string;
  prizeWinnerKey: string;
  keyword: any;
  stores: string;
  winnerType: string;
  expirationDate: string;
  invitedBy: string | undefined | null;
  fulfilmentPartner: string;
  submittedToFullfillment: boolean;
  declaration: {
    firstName: string;
    lastName: string;
    legalName: string;
    email: string;
    address: string;
    address2: string;
    city: string;
    province: string;
    postal: string;
    phone: string;
    claimedTime: string;
    signature: string;
  };
};

const FilteredWinners = ({ winnersData, prizeCount }: { winnersData: (filteredWinners | undefined)[], prizeCount: string }) => {
  const [filterTable, setFilterTable] = useState("");
  const [loading, setLoading] = useState(false);
  const profile = useStores().authStore.user.profile.toLowerCase();
  const [selectedWinner, setSelectedWinner] = useState("");
  const storeKeyword = useStores().keywordStore;
  const keyword = storeKeyword.keyword;
  const inputRef = useRef<HTMLDivElement>(null);
  const [pdfvars, setpdfvars] = useState<any>();
  const [isOpen, setIsOpen] = useState(false);
  const winners_nonguests = winnersData.filter(w => w?.winnerType !== "Guest")
  const winners_guests = winnersData.filter(w => w?.winnerType === "Guest")
  const guestCountPerWinner = useGetGuestCount(keyword)

  const validWinnerCount = (data: filteredWinners[] | any[]) => data.filter(w => w?.status === "Claimed").length + data.filter(w => w?.status === "Pending Claim").length
  const canSelect = !(validWinnerCount(winners_nonguests ?? []) > +prizeCount)

  const resendClaimEmail = async (prizeWinnerKey: string, sessionKey: string) => {
    if (!loading) {
      setLoading(true);
    }
    const res: any = await campaignClient.call("resendClaimEmail", {
      prizeWinnerKey,
      sessionKey,
    });

    if (!res.error) {
      showToast({
        content: `Prize email sent successfully.`,
        duration: 3000,
        error: false,
      });
    }
    if (res.error) {
      showToast({
        content: `Error while sending prize email.`,
        duration: 3000,
        error: true,
      });
    }
    setLoading(false);
  };
  const hideColumns =
    profile.toLowerCase() === "shipping"
      ? [
        "action",
        "expireDate",
        "email",
        "fulfilmentPartner",
        "submittedToFullfillment",
        "status",
        "expirationDate",
        "releaseForm"
      ]
      : profile.toLowerCase() === "standard"
        ? ["email", "lastName", "phone", "releaseForm"]
        : [];

  const columns = TableColumns({ resendClaimEmail, loading, setSelectedWinner, canSelect });
  const getSignatureUrl = async (key: string) => {
    const res: any = await campaignClient.call("downloadUserFile", { key, expirySeconds: 5000 });
    return res.downloadUrl;
  };
  const setPdfArgs = useCallback(async () => {
    if (winnersData && selectedWinner) {


      let winner = winnersData.find((w) => w?.prizeWinnerKey === selectedWinner);


      if (winner) {


        setIsOpen(true);


        const sig = await getSignatureUrl(winner.declaration.signature);
        const base64_signature = await imageUrlToBase64(sig)

        setpdfvars({
          parentPrizeWinner: winner!.winnerType === "Guest" ? "Guest" : "",
          contestType: keyword.contestType,
          isTicket: keyword.contestType.toLowerCase() === "ticket",
          isTrip: keyword.contestType.toLowerCase() === "trip",
          isNfl: winner!.prize.toLowerCase().includes("nfl"),
          prizeDescription: winner!.prize,
          keyword,
          address: winner!.declaration.address,
          expiryDate: winner!.expirationDate,
          address2: winner!.declaration.address2,
          firstName: winner!.declaration.firstName,
          lastName: winner!.declaration.lastName,
          city: winner!.declaration.city,
          phone: winner!.declaration.phone,
          postal: winner!.declaration.postal,
          province: winner!.declaration.province,
          signature: base64_signature,
          preferredLanguage: winner!.language,
          claimDate: winner.declaration.claimedTime
        });
      }
    }
  }, [winnersData, selectedWinner, keyword]);

  const fullReport = useMemo(() => {
    if (winnersData) {
      return winnersData.map((rp) => {
        return {
          "First Name": rp?.firstName,
          "Last Name": profile === "standard" ? "" : rp?.lastName,
          "Email Address": profile === "standard" ? "" : rp?.email,
          "Phone Number": profile === "standard" ? "" : rp?.phone,
          // City: rp.city,
          // Province: rp.province,
          "Prize Won": rp?.prize,
          "Prize Win Time": rp?.prizeWinTime,
          "Prize Claim Time": rp?.prizeClaimTime,
          Keyword: rp?.keyword,
          WinnerType: rp?.winnerType,
          "Expiration date": rp?.expirationDate || "N/A",
          "Claim Status": rp?.status,
          "Claim first name": rp?.declaration?.firstName || "N/A",
          "Claim last name": rp?.declaration?.lastName || "N/A",
          "Claim Email address": rp?.declaration?.email || "N/A",
          "Claim Address line1": rp?.declaration?.address || "N/A",
          "Claim Address line2": rp?.declaration?.address2 || "N/A",
          "Claim City": rp?.declaration?.city || "N/A",
          "Claim Province": rp?.declaration?.province || "N/A",
          "Claim Postal Code": rp?.declaration?.postal || "N/A",
          "Claim Phone Number": rp?.declaration?.phone || "N/A",
          Stores: rp?.stores,
          "Fulfillment partner": rp?.fulfilmentPartner || "N/A",
          "Submitted To Fullfillment": rp?.submittedToFullfillment || "N/A",
          prizeWinnerKey: rp?.prizeWinnerKey,
          "Invited By": rp?.invitedBy
        };
      });
    }
  }, [winnersData, profile]);

  const standardReport = useMemo(() => {
    if (winnersData) {
      return winnersData
        .filter((p) => p !== undefined)
        .map((rp) => {
          return {
            "First Name": rp?.firstName,
            "Prize Won": rp?.prize,
            "Prize Win Time": rp?.prizeWinTime,
            Keyword: rp?.keyword,
            WinnerType: rp?.winnerType,
            "Expiration date": rp?.expirationDate || "N/A",
            Stores: rp?.stores,
            "Status": rp?.status,

          };
        });
    }
  }, [winnersData]);

  const WinnerReportData = useMemo(() => {
    if (winnersData) {
      return winnersData.map((rp) => {
        let temprepo: { [key: string]: string | any[] } = {
          "First Name": rp?.firstName || "",
          "Last Name": rp?.lastName || "",
          "Email Address": rp?.email || "",
          "Phone Number": rp?.phone || "",
          // City: rp.city,
          // Province: rp.province,
          "Prize Won": rp?.prize || "",
          "Prize Win Time": rp?.prizeWinTime.toLocaleString() || "",
          "Prize Claim Time": rp?.prizeClaimTime.toLocaleString() || "",
          Keyword: rp?.keyword,
          WinnerType: rp?.winnerType || "",
          "Claim Status": rp?.status || "",
          "Claim first name": rp?.declaration?.firstName || "N/A",
          "Claim last name": rp?.declaration?.lastName || "N/A",
          "Claim Email address": rp?.declaration?.email || "N/A",
          "Claim Address line1": rp?.declaration?.address || "N/A",
          "Claim Address line2": rp?.declaration?.address2 || "N/A",
          "Claim City": rp?.declaration?.city || "N/A",
          "Claim Province": rp?.declaration?.province || "N/A",
          "Claim Postal Code": rp?.declaration?.postal || "N/A",
          "Claim Phone Number": rp?.declaration?.phone || "N/A",
          Stores: rp?.stores || "",
        };
        return temprepo;
      });
    }
  }, [winnersData]);
  const printDocument = async () => {
    if (inputRef.current && selectedWinner) {

      const image = new Image()
      image.crossOrigin = "Anonymous"
      image.src = pdfvars.signature + "?not-from-cache-please"
      const data = await axios.get(pdfvars.signature);
      const pdf = new jsPDF();

      await pdf.html(inputRef.current, {
        margin: [17, 10, 16, 10],
        callback: function (doc) {
          doc.save(
            `${pdfvars?.preferredLanguage === "fr"
              ? "Déclaration et exonération"
              : "Declaration and Release"
            }.pdf`
          );
        },
        html2canvas: { scale: 0.3 },
      });
      setSelectedWinner("");
    }
  };

  useEffect(() => {
    setPdfArgs();
  }, [selectedWinner, setPdfArgs]);

  return winnersData.length > 0 ? (
    <>
      <div className="main__head">

        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <h2 className="main__title">Winners</h2>
                <p>Number of Prizes: {prizeCount} </p>
                <p>Prizes - Claimed: {winners_nonguests.filter(w => w?.status === "Claimed").length} </p>
                <p>Prizes - Unclaimed: {winners_nonguests.filter(w => w?.status === "Pending Claim").length} </p>
                <p>Expired Prizes: {winners_nonguests.filter(w => w?.status === "Expired").length}
                </p>
              </div>
            </li>
            <li>
              {guestCountPerWinner &&
                <>
                  <div className="info-box">
                    <h2 className="main__title">Guests</h2>
                    <p>Per Winner: {guestCountPerWinner} </p>
                    <p>Prizes - Claimed: {winners_guests.filter(w => w?.status === "Claimed").length} </p>
                    <p>Prizes - Unclaimed: {winners_guests.filter(w => w?.status === "Pending Claim").length} </p>
                    <p>Expired Prizes: {winners_guests.filter(w => w?.status === "Expired").length}
                    </p>
                  </div>

                </>
              }

            </li>
          </ul>
        </div>


      </div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>
              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>
            <CSVLink
              filename={`Campaign-winners-Report-${new Date().toLocaleDateString()}`}
              className="btn btn--medium btn--mobile-small"
              style={{ marginLeft: "2rem" }}
              data={
                profile === "shipping"
                  ? WinnerReportData ?? []
                  : profile === "standard"
                    ? standardReport ?? []
                    : fullReport ?? []
              }
              asyncOnClick={true}
              target="_blank"
              aria-disabled={fullReport?.length ? false : true}>
              Download Winners Report
            </CSVLink>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={winnersData ? winnersData : []}
            tablePageSize={15}
            filterValue={filterTable}
            hideColumns={hideColumns}
          />
        </div>
      </div>

      <Modal show={isOpen} onHide={() => { }} centered>
        <Modal.Body style={{ padding: "30px 40px" }}>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setIsOpen(false);
            }}>
            <span aria-hidden="true">&times;</span>
          </button>

          {isOpen && !pdfvars ? <PageLoader /> : <ClaimformPdf winnerDetails={pdfvars} ref={inputRef} />}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary small"
            onClick={async () => {
              setIsOpen(false);
              printDocument();
            }}>
            Download PDF
          </button>
          <button
            type="button"
            className="btn btn-secondary small outline"
            onClick={() => {
              setIsOpen(false);
              setSelectedWinner("");
            }}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <p>Currently there are no records to display.</p>
  );
};

export default FilteredWinners;
