import { makeAutoObservable, observable, action } from "mobx";
import { StandardDynamicSettings } from "../types/keywordTypes";

export type storeKeyword = {
  word:string,
  keywordKey: string,
  shipping: { email: string, notificationTime:string , partNumber:string, projectNumber:string },
  contestType:string,
  dynamicSettings:StandardDynamicSettings,
  startTime:string,
  endTime:string
}


class KeywordStore {
  keyword = {
    word: "",
    keywordKey: "",
    shipping: { email: "", notificationTime: "", partNumber: "", projectNumber: "" },
    contestType:"",
    dynamicSettings:{} as StandardDynamicSettings,
    startTime:"",
    endTime:""
  };

  constructor() {
    makeAutoObservable(this, {
      keyword: observable,
      setKeyword: action,
    });
  }

  setKeyword(item: { word: string; keywordKey: string; shipping: any ;contestType:string,dynamicSettings:any,startTime:string,endTime:string}) {
    this.keyword = { ...item };
  }
}

export default KeywordStore;
