import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";
import { byKey, getMatchLocation } from "../../helpers/utils";
import { useStores } from "../../stores";
import { useNavigate } from "react-router";
import FilteredWinners from "./FilteredWinners";
import SearchKeyword from "../../Components/SearchKeyword";
import dayjs from "dayjs";
import { useGetGrandprizedraws, useGetGuestCount, useGetPrizes, useGetPrizeWinners } from "../../helpers/hooks";

function Winners() {
  const storeKeyword = useStores().keywordStore;
  const user = useStores().authStore.user;
  const [keyword, setKeyword] = useState(storeKeyword.keyword);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (storeKeyword.keyword.word) {
      setKeyword(storeKeyword.keyword);
    } else {
      navigate("/winners");
    }
  }, [storeKeyword.keyword, navigate]);

  const prizes = useGetPrizes()
  const grandPrizeDraws = useGetGrandprizedraws(storeKeyword.keyword.keywordKey)
  const guestCount = useGetGuestCount(keyword)
  const winners = useGetPrizeWinners(keyword.startTime, grandPrizeDraws.data, guestCount)


  const { isLoading: isLoadingLocations, data: keywordLocations } = useQuery(
    ["getKeywordLocations", { keyword }],
    async () => {
      const res: MainDbReturnTypes.KeywordLocations = await campaignClient.call(
        "getKeywordLocations",
        {
          keywordKey: keyword.keywordKey,
        }
      );
      return res.keywordLocations;
    },
    { enabled: !!keyword.keywordKey }
  );


  function capitalizeFirstLetter(data: string) {
    return data.charAt(0).toUpperCase() + data.slice(1);
  }

  const getLegalName = (
    firstName: string | undefined | null,
    lastName: string | undefined | null
  ) => {
    if (firstName && lastName) {
      return capitalizeFirstLetter(firstName) + " " + capitalizeFirstLetter(lastName);
    } else if (firstName) {
      return capitalizeFirstLetter(firstName);
    } else return "N/A";
  };

  const data = useMemo(() => {

    if (winners.data && prizes.data && keywordLocations && grandPrizeDraws.data) {

      // filter winners between keyword start date and end date
      const prizeWinners = winners.data
      const filteredWinners = prizeWinners.filter(w => !w.forfeitTime)

      return filteredWinners
        .map((rp) => {

          const participant = rp.participant;
          const expiryDays = keyword.dynamicSettings?.claimExpiry ? +keyword.dynamicSettings.claimExpiry : 10
          const expireTime = dayjs(rp.creationTime).add(expiryDays, "day").format("YYYY/MM/DD")
          const newParticipant = {
            firstName: participant.firstName || "",
            lastName: participant.lastName || "",
            language: participant.preferredLanguage || "en",
            email: participant.email || "",
            phone: participant.phone || "",
            // city: participant.city,
            // province: participant.province,
            prizeWinTime: new Date(rp.creationTime),
            prizeClaimTime: rp.declarationAndRelease?.creationTime
              ? new Date(rp.declarationAndRelease?.creationTime)
              : "",
            fulfillTime: rp.declarationAndRelease
              ? new Date(rp.declarationAndRelease.creationTime)
              : "NA",
            updateTime: rp.updateTime ? new Date(rp.updateTime) : "NA",
            status: rp.declarationAndRelease
                ? "Claimed"
                : dayjs().isAfter(dayjs(expireTime))
                  ? "Expired"
                  : "Pending Claim",
            prize: prizes.data[rp.prizeKey] ? prizes.data[rp.prizeKey][0].prizeName : "NA",
            sessionKey: participant.sessionKey,
            prizeWinnerKey: rp.prizeWinnerKey,
            keyword: keyword.word,
            stores: keywordLocations.length
              ? rp.participant.tags
                .filter((t) => t.includes("location"))
                .map(
                  (l) =>
                    keywordLocations.find(
                      (p) => p.keywordLocationKey === l.replace("location:", "")
                    )?.name
                )
                .filter((l) => l)
                .join(",")
              : "",
            winnerType: rp.parentPrizeWinnerKey ? "Guest" : "Primary",
            expirationDate: dayjs(expireTime).format("MM/DD/YYYY"),
            fulfilmentPartner: keyword.shipping?.email || "N/A",
            submittedToFullfillment: keyword.shipping?.notificationTime
              ? dayjs().isAfter(keyword.shipping.notificationTime)
              : false,
            invitedBy: rp.parentPrizeWinnerKey ? prizeWinners.find(w => w.prizeWinnerKey === rp.parentPrizeWinnerKey)?.participant.email : "",

            declaration: {
              firstName: rp.declarationAndRelease?.firstName || "",
              lastName: rp.declarationAndRelease?.lastName || "",
              email: rp.declarationAndRelease?.email || "",
              legalName:
                rp.declarationAndRelease ?
                  getLegalName(
                    rp.declarationAndRelease.firstName,
                    rp.declarationAndRelease?.lastName
                  ) : "",
              address: rp.declarationAndRelease?.address || "",
              address2: rp.declarationAndRelease?.address2 || "",
              city: rp.declarationAndRelease?.city || "",
              province: rp.declarationAndRelease?.province || "",
              postal: rp.declarationAndRelease?.postal || "",
              phone: rp.declarationAndRelease?.phone || "",
              claimedTime: rp.declarationAndRelease?.creationTime || "",
              signature: rp.declarationAndRelease?.digitalSignatureUrl || "",
            },
          };

          if (user.profile === "shipping" && storeKeyword.keyword.shipping.email) {
            const pickupStores = keywordLocations
              .map((k) => {
                if (k.dynamicSettings && k.dynamicSettings.pickupInStore) {
                  return k.name;
                }
              })
              .filter((v) => v);

            if (pickupStores.length <= 0) {
              return newParticipant;
            } else {
              const filterGrandPrizeDraws = grandPrizeDraws.data.filter(
                (d) => d.grandPrizeDrawKey === rp.grandPrizeDrawKey
              );

              const prizeDrawTags = filterGrandPrizeDraws[0].parameters.allTags || [];

              const location = getMatchLocation({
                participantTags: rp.participant.tags,
                prizeDrawTags,
                keywordLocations,
              });

              if (pickupStores.indexOf(location?.name) < 0) {
                return newParticipant;
              }
            }
          }

          if (user.profile !== "shipping") {
            return newParticipant;
          }
        })
        .filter((v) => v);
    }
  }, [winners.data, prizes, keywordLocations, keyword, grandPrizeDraws, storeKeyword.keyword.shipping.email, user.profile]);

  if (winners.isLoading || prizes.isLoading || isLoadingLocations || grandPrizeDraws.isLoading) {
    return <PageLoader />;
  }
  return (
    <>
      {keyword.keywordKey ? <FilteredWinners winnersData={data ? data : []} prizeCount={keyword.dynamicSettings.prizeCount || "0"} /> : <SearchKeyword />}
    </>
  );
}

export default Winners;
